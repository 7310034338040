<template lang="pug">
div
  v-img(:src="value.bild" :aspectRatio="value.aspectRatio" v-if="value.bild")

  v-stepper.wide-steps.mb-6.mt-1(v-model="step" alt-labels tile flat v-if="alleTermine.length")
    v-stepper-header.elevation-0
      v-stepper-step(:complete="step > 1 || step == ''" step="1" edit-icon="mdi-check")
        .pb-3 Location
        | {{ort && ort.name}}
      v-divider
      v-stepper-step(:complete="step > 2 || step == ''" step="2" edit-icon="mdi-check")
        .pb-3 {{$route.query.wl ? 'Warteliste' : 'Termin' }}
        template(v-if="$route.query.w")
          | {{wlTage.slice(0, 5).map((t) => t.replace(/(\d+)-(\d+)-(\d+)/, '$3.$2.')).join(" | ")}}
          template(v-if="wlTage.length > 5")
            //- |  (+{{wlTage.length - 5}} Tage)
            |  | …
          |  {{wlTage[0] && wlTage[0].replace(/(.*)-(.*)-(.*)/, '$1')}}
        template(v-else)
          | {{datum(termin && termin.zeit)}}
          br
          | {{zeit(termin && termin.zeit)}}
      v-divider
      v-stepper-step(:complete="step > 3 || step == '' || isLoggedIn" step="3" edit-icon="mdi-check") Account
      v-divider
      v-stepper-step(:complete="step > 4 || step == '' || finished" step="4" edit-icon="mdi-check") {{warteliste ? 'Eintragung' : gruppenanmeldung ? 'Reservierung' : 'Anmeldung'}}

    .mx-n2: v-stepper-items(ref="top")
      v-stepper-content(step="1")

      v-stepper-content(step="2")

      v-stepper-content(step="3")
        div(v-html="html[0]")

        v-row(wrap).pa-4
          v-col(cols="12" md="5")
            v-card(v-if="ort").primary.white--text.text-center
              v-img(:src="ort.bild" v-if="ort.bild" :aspect-ratio="4/3")
              v-card-title.justify-center {{ort.name}}
              v-card-text.white--text.pt-0
                template(v-if="warteliste == 0")
                  | {{datum(termin && termin.zeit)}} | {{zeit(termin && termin.zeit)}}
                template(v-else)
                  | {{wlTage.map((t) => t.replace(/(.*)-(.*)-(.*)/, '$3.$2.')).join(" | ")}}
                  br
                  | {{wlTage[0] && wlTage[0].replace(/(.*)-(.*)-(.*)/, '$1')}} Warteliste
                br
                .mt-4
                strong Gruppe
                |  {{gruppe.vollerName}}
                br
                strong Gruppen-Nr.
                |  {{gruppe.jahr}}-{{gruppe.jid}}

          v-col(cols="12" md="7")
            template(v-if="isLoggedIn")
              v-text-field(label="E-Mail *" autocomplete="email" disabled :value="$store.state.user && $store.state.user.email")
              v-btn(color="primary" outlined x-large @click="anmelden()") Weiter

            template(v-else)
              v-tabs.blocky-tabs.mb-4(grow show-arrows v-model="accountErstellenTab" v-if="step == 3")
                v-tab Account Erstellen
                v-tab Einloggen

              v-messages.mb-2(color="error" :value="[].concat(gqlErrorsFor('userWithTerminCreate', 'wartelistenTermine'), gqlErrorsFor('userWithTerminCreate', 'termin'))")
              v-tabs-items(v-model="accountErstellenTab")
                v-tab-item
                  template(v-if="!accountCreated")
                    v-form(@submit="anmelden")
                      v-text-field(label="E-Mail *" autocomplete="email" v-model="edit.email" @blur="edit.email = edit.email.toLowerCase()" :error-messages="gqlErrorsFor('userWithTerminCreate', 'email')" persistent-hint)
                      v-text-field(label="E-Mail Bestätigung *" autocomplete="email" v-model="edit.emailConfirmation" :error-messages="gqlErrorsFor('userWithTerminCreate', 'emailConfirmation')")
                      v-text-field(label="Passwort *" autocomplete="new-password" v-model="edit.password" type="password" :error-messages="gqlErrorsFor('userWithTerminCreate', 'password')" persistent-hint hint="mindestens 8 zeichen")
                      v-text-field(label="Passwort Bestätigung *" autocomplete="new-password" v-model="edit.passwordConfirmation" type="password" :error-messages="gqlErrorsFor('userWithTerminCreate', 'passwordConfirmation')")
                      accept-datenschutz(v-model="edit.datenschutzAccepted" :error-messages="gqlErrorsFor('userWithTerminCreate', 'datenschutzAccepted')")
                      v-btn.my-4(color="primary" outlined x-large type="submit" :loading="createLoading > 0" :disabled="actionsDisabled") Account Erstellen

                  template(v-else)
                    .title Schauen Sie bitte in die E-Mail, die wir Ihnen soeben an:
                    .text-h3.py-4 {{accountCreated}}
                    .title  geschickt haben und bestätigen sie, dass es ihre E-Mail Adresse ist.

                v-tab-item
                  jf-login(inline)

      v-stepper-content(step="4")
        div(v-html="html[1]")

        v-row(wrap v-if="editUser").px-2
          v-col(cols="12")
            template(v-if="angemeldeteTeilnehmer.length")
              strong Bereits angemeldet
              template(v-for="tnTermin in angemeldeteTeilnehmer")
                br
                | {{tnTermin.tns.map(tn => tn.vollerName).join(", ")}} <strong>am</strong> {{datumZeit(tnTermin.zeit)}} <strong>{{tnTermin.ort.adverbial}}</strong> {{tnTermin.ort.name}}
            template(v-if="gruppenLeiter.length")
              br.mb-2
              strong Gruppenreservierung{{gruppenLeiter.length > 1 ? 'en' : ''}}
              template(v-for="gruppe, gruppeI in gruppenLeiter")
                br
                | {{gruppe.teilnehmer.filter((tn) => tn.status != 'Storniert').length}} Plätze {{gruppe.vollerName}} <strong>am</strong> {{datumZeit(gruppe.termin.zeit)}} <strong>{{gruppe.termin.ort.adverbial}}</strong> {{gruppe.termin.ort.name}}
            template(v-if="aufWartelisten.length")
              br.mb-2
              strong Auf warteliste{{aufWartelisten.length > 1 ? 'n' : ''}}
              template(v-for="wl in aufWartelisten")
                br
                | {{wl.schulGruppenname}}
                template(v-for="ort, ortI in wl.orte")
                  br(v-if="ortI > 0")
                  |  {{ort.adverbial}} {{ort.name}}: {{ort.daten.join(" ")}} {{ort.jahr}}

          v-col(cols="12" sm="6"): v-text-field(label="E-Mail *" v-model="editUser.email" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'email')" disabled)
          v-col(cols="12" sm="6"): v-text-field(label="Telefonnummer *" hint="(mobil/tagsüber)" persistent-hint v-model="editUser.telefon" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'phone')")
          template(v-if="!editUser.komplett")
            v-col(cols="12" sm="6"): v-text-field(label="Vorname (Vertragspartner)*" v-model="editUser.vorname" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'first_name')" :disabled="editUser.komplett")
            v-col(cols="12" sm="6"): v-text-field(label="Nachname (Vertragspartner)*" v-model="editUser.nachname" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'last_name')" :disabled="editUser.komplett")
            v-col(cols="12"): v-text-field(label="Adresszusatz" v-model="editUser.adresszusatz" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'adresszusatz')" :disabled="editUser.komplett" hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint)
            v-col(cols="12" sm="6"): v-text-field(label="Straße *" v-model="editUser.strasse" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'street')" :disabled="editUser.komplett")
            v-col(cols="12" sm="6"): v-text-field(label="Hausnummer *" v-model="editUser.strasseNr" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'street_no')" :disabled="editUser.komplett")
            v-col(cols="12" sm="6"): v-text-field(label="PLZ *" v-model="editUser.plz" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'zip')" :disabled="editUser.komplett")
            v-col(cols="12" sm="6"): v-text-field(label="Ort *" v-model="editUser.ort" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'city')" :disabled="editUser.komplett")
            v-col(cols="12" v-if="termin && termin.ticketpreis && termin.ticketpreis.sozialRabatt")
              v-checkbox(label="Ich erhalte ALG II oder ich und / oder mein Kind besitze/n den Berlinpass und möchte/n den Sozialrabatt in Anspruch nehmen." v-model="editUser.sozialRabatt" :disabled="editUser.komplett" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialrabatt')")
              template(v-if="editUser.sozialRabatt && (!editUser.komplett || !editUser.sozialrabattBestaetigt)")
                v-radio-group.mt-0(v-model="editUser.sozialausweisKommt" v-if="!editUser.komplett || editUser.sozialausweisKommt == 'later'" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'submit_sozialausweis')")
                  v-radio.mb-0(label="Den entsprechenden Nachweis lade ich hoch." value="upload" v-if="!editUser.komplett")
                  v-radio.mb-0(label="Den entsprechenden Nachweis sende ich Ihnen innerhalb von 7 Tagen per Post, Fax oder E-Mail." value="later" :disabled="editUser.komplett")
                p(v-if="editUser.sozialausweisKommt === 'upload' && !editUser.komplett")
                  file-input(v-model="editUser.sozialausweisName" base64 @base64="editUser.sozialausweisFile = $event" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialausweisFile').concat(gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialausweisFileName')).concat(gqlErrorsFor('userWithTeilnehmerUpdate', 'sozialausweis'))")
                p(v-if="editUser.sozialausweisKommt === 'later'")
                  | Postadresse: {{constants && constants.infoPost}}<br>
                  | Fax: {{constants && constants.infoFax}}, E-Mail: {{constants && constants.infoMail}}

        v-row.px-2.pb-2(wrap v-if="currentUser && editUser")
          v-col(cols="12")
            .mt-3.mb-3.title Angaben zu Ihrem Kind / Ihren Kindern

          template(v-for="tn, tnI in editTns")
            template(v-for="editTn in [1]")
              v-col(cols="12"): strong  {{strftime(tn.termin.zeit, 'dddd D. MMM Y H:mm [Uhr]')}} {{tn.termin.ort.adverbial}} {{tn.termin.ort.name}}
              v-col(cols="12") <strong>Gruppe</strong> {{gruppe.vollerName}} <strong>Gruppen-Nr.</strong> {{gruppe.jahr}}-{{gruppe.jid}}
              v-col(cols="12") <strong>Preis:</strong> {{tn.termin.preisDetailsAll}}
              v-col(cols="12" v-if="gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'base').length"): .error--text {{gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'base').join(", ")}}
              v-col(cols="12" v-if="tn.gruppe") Gruppe: {{tn.gruppe.jid}} {{tn.gruppe.vollerName}}
              v-col(cols="12" sm="6"): v-text-field(label="Vorname der_s Jugendlichen *" v-model="tn.vorname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'teilnehmer_vorname')" :disabled="tn.gebucht")
              v-col(cols="12" sm="6"): v-text-field(label="Nachname der_s Jugendlichen *" v-model="tn.nachname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'teilnehmer_nachname')" :disabled="tn.gebucht")
              v-col(cols="12"): v-text-field(label="Lautschrift" v-model="tn.aussprache" persistent-hint hint="Wie wird der Name ausgesprochen? Besonderheiten?" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'aussprache')" :disabled="tn.gebucht")
              v-col(cols="12" sm="6"): radio-group(label="Geschlecht *" row :mandatory="false" :items="geschlechter" v-model="tn.geschlecht" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'geschlecht')" :disabled="tn.gebucht")
              v-col(cols="12" sm="6"): input-date(label="Geburtstag *" v-model="tn.geburtstag" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'geburtstag')" :disabled="tn.gebucht")
              v-col(cols="12" sm="6"): v-text-field(label="Gfls. Behinderung" v-model="tn.behinderung" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'behinderung')" :disabled="tn.gebucht")
              v-col(cols="12" sm="6"): v-text-field(label="E-Mail-Adresse der_s Jugendlichen" v-model="tn.email" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'teilnehmer_email')" :disabled="tn.gebucht" hint="(Diese E-Mail-Adresse nutzen wir ausschließlich, um Ihr Kind auf Angebote im Vorbereitungsprogramm direkt aufmerksam machen zu können.)" persistent-hint)
              template(v-if="!tn.gruppe")
                template(v-if="tn.gebucht")
                  v-col(cols="12" sm="6"): select-schule(label="Schule *" none other :value="tn.schule && tn.schule.id" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'school_id')" disabled)
                  v-col(cols="12" sm="6"): v-text-field(:label="!tn.schule ? 'Schule und Klasse *' : 'Klasse *'" :value="tn.gruppenname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'gruppenname')" disabled)
                template(v-else)
                  v-col(cols="12" sm="6"): select-schule(label="Schule *" none other v-model="tn.schuleId" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'school_id')")
                  v-col(cols="12" sm="6"): v-text-field(:label="tn.schuleId === '0' ? 'Schule und Klasse *' : 'Klasse *'" v-model="tn.gruppenname" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'gruppenname')" :disabled="tn.schuleId === '00'")
              v-col(cols="6"): v-checkbox(label="Gegebenenfalls abweichende Adresse der_s Jugendliche_n" v-model="tn.hatAdresse" :disabled="tn.gebucht")
              template(v-if="tn.hatAdresse")
                v-col(cols="12"): v-text-field(label="Adresszusatz" v-model="tn.adresszusatz" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_adresszusatz')" :disabled="tn.gebucht"  hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint)
                v-col(cols="12" sm="6"): v-text-field(label="Straße" v-model="tn.strasse" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_strasse')" :disabled="tn.gebucht")
                v-col(cols="12" sm="6"): v-text-field(label="Hausnummer" v-model="tn.hausnummer" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_hausnummer')" :disabled="tn.gebucht")
                v-col(cols="12" sm="6"): v-text-field(label="PLZ" v-model="tn.plz" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_plz')" :disabled="tn.gebucht")
                v-col(cols="12" sm="6"): v-text-field(label="Ort" v-model="tn.ort" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'tn_ort')" :disabled="tn.gebucht")
              v-col(cols="6" v-if="tn.termin.ort.juhu"): v-checkbox(label="Juhu-Mitglied werden" v-model="tn.werdeJuHuMitglied" :error-messages="!editTn ? [] : gqlErrorsFor('userWithTeilnehmerUpdate', tnI, 'werde_hvd_mitglied')" :disabled="tn.gebucht"): div(slot="label"): div(slot="label")
                div(v-html="constants && constants.werdeJuhuHtml || 'werde JuHu (...Lade)'")
              v-col(cols="12")
                v-btn(@click="removeTeilnehmer(tnI)" v-if="!tn.gebucht")
                  v-icon(left) mdi-minus
                  | eigenes Kind
              v-col(cols="12"): v-divider.my-2
          v-col(cols="12" v-if="termin")
            v-btn.mb-4( @click="addTeilnehmer(termin, gruppe)")
              v-icon(left) mdi-plus
              | eigenes Kind

          template(wrap).px-2
            v-col(cols="12" v-if="!currentUser.hatBekanntVon && (!currentUser.komplett || editTns.length)"): bekannt-von(v-model="editUser.bekanntVon" :sonstiges.sync="editUser.bekanntVonSonstiges" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'bekannt_von')")
            v-col.grey.lighten-5(cols="12" v-if="!currentUser.komplett && !currentUser.acceptPhotoVideo && !currentUser.acceptAgbs"): accept-newsletter(v-model="editUser.acceptInfoEmails" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_info_emails')")
            //- template(v-if="gruppenanmeldung == 0")
            v-col(cols="12" v-if="!currentUser.acceptPhotoVideo"): accept-fotos(v-model="editUser.acceptPhotoVideo" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_photo_video')")
            v-col(cols="12" v-if="!currentUser.acceptAgbs"): accept-agb(v-model="editUser.acceptAgbs" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_agbs')")
            v-col(cols="12" v-if="!currentUser.acceptDatenschutz"): accept-datenschutz(v-model="editUser.acceptDatenschutz" :error-messages="gqlErrorsFor('userWithTeilnehmerUpdate', 'accept_datenschutz')")

          v-col(cols="12").mt-8: v-btn(color="primary" @click="updateUser" :loading="userLoading > 0" :disabled="actionsDisabled") Anmelden

      v-stepper-content(step="5")
        .text-center
          v-sheet(color="grey lighten-3").py-8.mb-8
            v-icon(style="font-size: 12rem; margin-right: -10.9rem" color="grey") mdi-checkbox-blank-circle-outline
            v-icon(style="font-size: 9rem" color="success") mdi-check
            h1 {{plaetzeGebucht}} Teilnehmer angemeldet!

        .mt-8(v-html="html[2]")

        .text-center.mt-8
          v-btn(outlined color="primary" to="/account") Account Bereich

</template>

<style lang="scss" scoped>
.blocky-tabs .v-tab {
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: #f5f5f6!important;
  color: rgba(0, 0, 0, 0.87)!important;
  margin: 3px;
  &:before {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.87);
    opacity: 0;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.v-tab--active:before, &:hover:before {
    opacity: 0.24;
  }
}
.wide-steps ::v-deep .v-stepper__step {
  flex-basis: 200px;
}
</style>

<script>
import gql from 'gql-id.macro'
import graphqlErrorObject from '../backend/mixins/graphql-error-object.js'
import QueryFilter from '../backend/mixins/query-filter'
import stapelActions from '../backend/mixins/stapel-actions'
import AcceptDatenschutz from './accept-datenschutz'
import AcceptAgb from './accept-agb'
import AcceptFotos from './accept-fotos'
import AcceptNewsletter from './accept-newsletter'
import InputDate from './input-date'
import JfLogin from './jf-login'
import { orderBy, uniqBy, sortBy } from 'lodash'
// import JfStepperStep from './jf-stepper-step.js'
// import Vue from 'vue'
// Vue.component('jf-stepper-step', JfStepperStep)
import moment from 'moment'

export default {
  name: 'jf-gruppencode',
  mixins: [graphqlErrorObject, stapelActions, QueryFilter],
  components: {
    // "jf-stepper-step": JfStepperStep
    AcceptDatenschutz,
    AcceptAgb,
    AcceptFotos,
    AcceptNewsletter,
    InputDate,
    JfLogin,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      // step: 1,
      gruppenlink: null,
      plaetzeGebucht: 0,
      finished: false,
      loginError: null,
      loginLoading: 0,
      createLoading: 0,
      userLoading: 0,
      // accountErstellenTab: 0,
      // ort: null,
      // jahr: null,
      // termin: null,
      // gruppenanmeldung: null,
      constants: {},
      // alleTermine: [],
      allTage: [],
      // loggedIn: false, // FIXME
      // mailed: false,
      accountCreated: null,
      emailSentTo: null,
      addTnWhenTerminLoaded: false,
      edit: {
        // plaetze: 1,
        email: '',
        password: ''
      },
      editUser: null,
      actionsDisabled: false,
      // termin: {},
      // user: {},
      // editTns: [{buchungen: [], termin: {ort: {}}}],
      editTns: [],
      tn: {},
      gruppe: null,
      loading: 0,
      geschlechter: [
        { text: 'Weiblich', value: 'female' },
        { text: 'Männlich', value: 'male' },
        { text: 'Divers', value: 'not_specified' },
      ],
    }
  },
  apollo: {
    // orte: {
    //   query() {
    //     return gql`query jfTerminAnmeldungVueOrte($jahr: Int) {
    //       orte(order: "name:asc", terminJahr: $jahr) {
    //         id
    //         name
    //         label
    //         label2
    //         bild
    //         # ... on Ort @include(if: $edit) {
    //         #   adverbial
    //         #   strasse
    //         #   hausnummer
    //         #   plz
    //         #   ort
    //         #   land
    //         #   juhu
    //         #   latitude
    //         #   longitude
    //         #   bankEmpfaenger
    //         #   bankIban
    //         #   bankBic
    //         #   bankName
    //         #   oeffnungszeiten
    //         #   ruecktrittsbedingungen
    //         #   ruecktrittsbedingungenGk
    //         #   anmeldehinweise
    //         #   briefbogenOben
    //         #   briefbogenSeite
    //         #   briefbogenUnten
    //         #   impressum
    //         #   verbandId
    //         #   vpRegionenIds(jahr: $jahr)
    //         #   bild
    //         # }
    //       }
    //     }`
    //   },
    //   variables() {
    //     return {
    //       jahr: +this.$store.state.jahr,
    //     }
    //   },
    // }
    // alleTermine: {
    //   query: gql`query jfTerminAnmeldungVueTermine($region: String) {
    //     alleTermine: termine(region: $region, q: "{\"sorts\":[\"zeit\",\"ort_name\"],\"sichtbare\":true,\"kommende\":true}", role: ANON) {
    //       id
    //       zeit
    //       jahr
    //       tnFreiDisplay
    //       tnFreiDisplayMehr
    //       tnReserviertDisplay
    //       anmeldungGeschlossen
    //       anmeldungOffen
    //       anmeldeschlussInfoWithDefault
    //       infotextWithDefault
    //       zeigeWarteliste
    //       zeigeWartelisteAb
    //       voranmeldung
    //       preisDetailsAll
    //       ort {
    //         id
    //         name
    //         adverbial
    //         kuerzel
    //         region
    //         bild(size: "400x300#")
    //       }
    //     }
    //     constants {
    //       werdeJuhuHtml
    //       werdeJuhuDefault
    //       infoMail
    //       infoFax
    //       infoPost
    //     }
    //   }`,
      // update(response) {
      //   this.constants = response.constants // must be loaded before termin data to ensure default option is honored
      //   return response.alleTermine
      // },
    //   variables() {
    //     return {
    //       region: this.$route.path || null,
    //     }
    //   },
    //   // skip() {
    //   //   return !this.data
    //   // }
    // },
    alleFeiernReserviertText: {
      query: gql`query extraTermineVueReserviertText {
        alleFeiernReserviertText
      }`
    },
    currentConstants: {
      query: gql`query jfVueCurrentConstants {
        constants {
          werdeJuhuHtml
          werdeJuhuDefault
        }
      }`,
      update(response) {
        this.constants = response.constants
        console.log("constants", this.constants)
        return response.constants
      }
    },
    currentUser: {
      fetchPolicy: "network-only",
      query: gql`query jfTerminAnmeldungVueCurrentUser {
        currentUser {
          id
          email
          nachname
          vorname
          telefon
          strasse
          strasseNr
          adresszusatz
          plz
          ort
          acceptAgbs
          acceptDatenschutz
          acceptPhotoVideo
          hatBekanntVon
          acceptInfoEmails
          sozialrabattBeantragt
          sozialrabattBestaetigt
          sozialausweisKommt
          komplett
          gruppen {
            id
            vollerName
            termin {
              id
              zeit
              ort {
                id
                adverbial
                name
                juhu
              }
            }
            teilnehmer {
              id
              status
            }
          }
          teilnehmer {
            id
            vollerName
            status
            termin {
              id
              zeit
              ort {
                id
                adverbial
                name
              }
            }
          }
          wartelisten {
            id
            schulGruppenname
            gruppenname
            anzahlTeilnehmer
            # extraTermine
            termine {
              id
              zeit
              ort {
                id
                adverbial
                name
              }
            }
          }
        }
      }`
    },
    gruppe: {
      query: gql`query jfTerminAnmeldungVueGruppe($code: String!) {
        gruppe: gruppeCode(code: $code) {
          id
          jid
          jahr
          feierOrt
          feierTermin
          name
          numName
          vollerName
          termin {
            id
            zeit
            jahr
            tnFreiDisplay
            tnFreiDisplayMehr
            tnReserviertDisplay
            anmeldungGeschlossen
            anmeldungOffen
            anmeldeschlussInfoWithDefault
            infotextWithDefault
            zeigeWarteliste
            zeigeWartelisteAb
            voranmeldung
            preisDetailsAll
            ort {
              id
              name
              adverbial
              kuerzel
              region
              juhu
              bild(size: "400x300#")
            }
            ticketpreis {
              sozialRabatt
            }
          }
        }
      }`,
      skip() {
        return !this.gruppencode
      },
      variables() {
        return {
          code: this.gruppencode
        }
      },
      // update(result) {
      //   return result.data?.gruppen && result.data.gruppen[0]
      // },
    }
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(user) {
        if (user && !this.editUser?.email) {
          this.editUser = { sozialausweisName: null, bekanntVonSonstiges: null, bekanntVon: null, ...user }
        } else if (user && this.editUser) {
          this.editUser.komplett = user.komplett
        }
      }
    },
    step(step) {
      if (step == 4) {
        this.$apollo.queries.currentUser.refetch()
      }
    },
    termineAnJahrOrt(termine) {
      this.allTage = uniqBy(termine.map((t) => t.date))
    },
    alleTermine(ts) { // FIXME remove
      if (ts && ts.length) {
        // this.jahr = 2030
        // this.ort = ts[0].ort
        // this.termin = ts[0]
        // this.edit.email="@4.8"
        // this.edit.emailConfirmation="@4.8"
        // this.edit.password="12341234"
        // this.edit.passwordConfirmation="12341234"
        // this.edit.datenschutzAccepted=true
        // this.warteliste = 1
        // this.mailed = true
        // this.gruppenanmeldung = 0
        // this.accountErstellenTab = 1
      }
    },
    '$store.state.messages'() {
      this.loginLoading = 0;
    },
    accountErstellenTab() {
      this.gqlClearErrorsFor('userWithTerminCreate')
    },
    termine(tage) {
      let ts = this.$route.query.ts
      if (tage && tage.length && ts && !this.nTage.length) {
        ts = ts.split(",")
        this.queryFilterUpdates({ ts: null }, false)
        this.nTage = uniqBy(tage.filter((tag) => ts.indexOf(tag.id) > -1).map((tag) => tag.date))
      }
    },
    termin(termin) {
      if (termin && this.editTns.length == 0) {
        this.addTeilnehmer()
      }
    }
  },
  computed: {
    alleTermine() {
      if (this.gruppe?.termin) {
        return [this.gruppe.termin]
      } else {
        return []
      }
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    html() {
      return this.value.html.split("<hr data-STEP-SEPERATOR>")
    },
    step: {
      get() {
        if (this.isLoggedIn) {
          if (this.finished) {
            return 5
          } else {
            return 4
          }
        } else {
          return 3
        }
      },
      set(step) {
        if (step <= 1) {
          // this.ort = null
          this.queryFilterUpdates({ ort: null, jahr: null, ntage: null }, false)
          this.warteliste = 0
        }
        if (step <= 2) {
          // this.termin = null
          this.editTns = []
          this.queryFilterUpdates({ t: null, w: null, g: null }, false)
        }
        if (step <= 3) {
          // this.mailed = false
          this.accountCreated = null
        }
        if (step <= 4) {
          this.finished = false
          this.plaetzeGebucht = 0
          this.gruppenlink = null
        }
      }
    },
    nTage: {
      get() {
        const jahr = this.$route.query.jahr
        return this.$route.query.ntage?.split(",")?.map((t) => t.length > 5 ? t : `${jahr}-${t}`) || []
      },
      set(val) {
        const jahr = +this.$route.query.jahr
        const jahrRegexp = new RegExp(`^${jahr}-`)
        const tage = val.map((t) => t.replace(jahrRegexp, '')).join(",")
        this.queryFilterUpdate('ntage', tage, false)
      }
    },
    wlTage() {
      const nTage = this.nTage
      return this.allTage.filter((t) => nTage.indexOf(t) == -1)
    },
    warteliste: {
      get() {
        return +this.$route.query.wl || 0
      },
      set(wl) {
        this.queryFilterUpdate('wl', wl > 0 ? 1 : null, false)
      }
    },
    accountErstellenTab: {
      get() {
        return +this.$route.query.anm || 0
      },
      set(anm) {
        this.queryFilterUpdate('anm', anm > 0 ? 1 : null, false)
      }
    },
    gruppenanmeldung: {
      get() {
        const g = this.$route.query.g
        return g == 0 ? 0 : +g || null
      },
      set(g) {
        this.queryFilterUpdate('g', g == null ? null : g.toString(), false)
      }
    },
    ort() {
      return this.termin?.ort
    },
    orte() {
      const orte = uniqBy(this.alleTermine.map((t) => t.ort), 'id').map((o) => ({ ...o, jahre: [] }))
      orte.forEach((ort) => {
        this.alleTermine.forEach((termin) => {
          if (ort.id == termin.ort.id) {
            ort.jahre.push(+termin.zeit.substr(0, 4))
          }
        })
      })
      orte.forEach((ort) => {
        ort.jahre = sortBy(uniqBy(ort.jahre))
      })
      return orte
    },
    termineAnJahrOrt() {
      const ortId = this.$route.query.ort
      const jahr = this.$route.query.jahr
      return sortBy(this.alleTermine.filter((t) => t.ort.id == ortId && t.zeit.substr(0, 4) == jahr), 'zeit').map((t) => ({ ...t, date: t.zeit.substr(0, 10) }))
    },
    termin() {
      return this.alleTermine[0]
    },
    termine() {
      if (this.warteliste == 0) {
        return this.termineAnJahrOrt
      } else {
        return uniqBy(this.termineAnJahrOrt.filter((t) => t.zeigeWarteliste), 'date')
      }
    },
    allWlTermine() {
      return this.termineAnJahrOrt.filter((t) => t.anmeldungOffen && t.zeigeWarteliste)
    },
    wlTermine() {
      const wlTage = this.wlTage
      return this.allWlTermine.filter((t) => wlTage.indexOf(t.date) > -1)
    },
    gruppencode() {
      return this.$route.path.replace(/^\//, '')
    },
    angemeldeteTeilnehmer() {
      const tns = this.currentUser?.teilnehmer?.filter((tn) => tn.status != 'Storniert') || []
      const termine = uniqBy(tns, 'termin.id').map((tn) => ({ ...tn.termin, tns: [] }))
      tns.forEach((tn) => {
        const termin = termine.find((t) => t.id == tn.termin.id)
        termin?.tns?.push(tn)
      })
      return termine
    },
    aufWartelisten() {
      return (this.currentUser?.wartelisten || []).map((warteliste) => {
        const orte = uniqBy(warteliste.termine, 'ort.id').map((wl) => ({ ...wl.ort, termine: [] }))
        warteliste.termine.forEach((t) => {
          const ort = orte.find((o) => o.id == t.ort.id)
          ort.termine.push(t)
        })
        orte.forEach((ort) => {
          ort.jahr = this.datum(ort.termine[0]?.zeit).replace(/.*\./, '')
          const matchesJahr = new RegExp(`${ort.jahr || '^'}$`)
          ort.daten = uniqBy(ort.termine.map((t) => this.datum(t.zeit).replace(matchesJahr, '')))
        })
        return { ...warteliste, orte }
      })
    },
    gruppenLeiter() {
      return this.currentUser?.gruppen || []
    }
  },
  methods: {
    selectOrt(ortAuswahl, jahrAuswahl) {
      this.queryFilterUpdates({ ort: ortAuswahl.id, jahr: jahrAuswahl }, false)
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    datum(date) {
      // return strftime(date, 'dddd, D. MMM Y H:mm [Uhr]')
      return this.strftime(date, 'DD.MM.Y')
    },
    zeit(date) {
      return this.strftime(date, 'H:mm [Uhr]')
    },
    datumZeit(date) {
      return this.strftime(date, 'DD.MM.Y H:mm [Uhr]')
    },
    terminColor(termin) {
      if (!termin.anmeldungOffen || termin.tnFreiDisplay == 0) {
        return 'error'
      } else if (!termin.tnFreiDisplayMehr) {
        return 'warning'
      } else {
        return 'success'
      }
    },
    infotext(termin) {
      if (termin.anmeldungOffen) {
        if (termin.tnFreiDisplay > 0) {
          if (termin.tnFreiDisplayMehr) {
            return 'Plätze frei'
          } else {
            return `${termin.tnFreiDisplay} Plätze frei`
          }
        } else {
          if (termin.tnReserviertDisplay) {
            return this.alleFeiernReserviertText  || 'Keine Plätze frei'
          } else {
            return 'Keine Plätze frei'
          }
        }
      } else {
        if (termin.anmeldungGeschlossen) {
          return termin.anmeldeschlussInfoWithDefault || 'Keine Plätze frei'
        } else {
          return termin.infotextWithDefault || 'Keine Plätze frei'
        }
      }
            // template(v-else-if="termin.anmeldungOffen")
            //   template(v-if="termin.tnFreiDisplay > 0")
            //     span.text-success.neon(v-if="termin.tnFreiDisplayMehr")  Plätze frei
            //     span.text-warning.neon(v-else)  {{termin.tnFreiDisplay}} Plätze frei
            //     v-btn(@click="queryFilterUpdates({ anmeldungTermin: termin.id }, { selector: '#extra-termine-top' }, { push: true, keepPage: true })" color="primary") Anmelden
            //   template(v-else)
            //     span.text-danger.neon Keine Plätze frei
            //     template(v-if="termin.tnReserviertDisplay")
            //       v-menu(offset-y)
            //         v-btn(slot="activator") Infos
            //         v-card(max-width="500px"): v-card-text {{alleFeiernReserviertText}}
            // template(v-else)
            //   v-menu(offset-y)
            //     v-btn(slot="activator") Infos
            //     v-card(max-width="500px"): v-card-text
            //       div(v-if="termin.anmeldungGeschlossen" v-html="termin.anmeldeschlussInfoWithDefault")
            //       div(v-else v-html="termin.infotextWithDefault")
    },
    terminSelected(termin) {
      if (this.warteliste == 0) {
        if (termin && termin.anmeldungOffen && termin.tnFreiDisplay > 0) {
          // this.termin = termin
          this.queryFilterUpdates({ t: termin.id, w: null }, false)
        }
      } else {
        if (termin) {
          const nTage = this.nTage.map((t) => t)
          const i = nTage.indexOf(termin.date)
          if (i > -1) {
            nTage.splice(i, 1)
          } else {
            nTage.push(termin.date)
            nTage.sort()
          }
          this.nTage = nTage
        } else {
          // this.termin = {}
          this.queryFilterUpdates({ t: null, w: 1 }, false)
        }
      }
    },
    addTeilnehmer() {
      this.editTns.push({ geschlecht: null, termin: this.termin })
    },
    removeTeilnehmer(i) {
      this.editTns.splice(i, 1)
    },
    disableActions() {
      if (this.actionsDisabled) {
        return true
      } else {
        this.actionsDisabled = true
        setTimeout(() => {
          this.actionsDisabled = false
        }, 600)
        return false
      }
    },
    anmelden(event) {
      event?.preventDefault()
      if (!this.isLoggedIn) {
        if (this.accountErstellenTab == 0) {
          this.createAccount()
        } else {
          this.loginLoading++
          this.$store.dispatch('login', { email: this.edit.email, password: this.edit.password }).then(() => {
            this.loginLoading--
            this.loginError = null
          }, async (response) => {
            this.loginLoading--
            if (response.error) {
              this.loginError = response.error
            } else {
              this.loginError = null
            }
          })
        }
      } else {
        // this.mailed = true
        // this.accountCreaded = true
      }
    },
    createAccount: async function() {
      if (this.disableActions()) {
        return
      }
      // this.accountCreated = this.edit.email
      // return
      const iswarteliste = this.warteliste == 1
      const that = this
      const variables = {
        ...this.edit,
        termin: iswarteliste ? null : this.termin?.id,
        wartelistenTermine: iswarteliste ? this.wlTermine.map((t) => t.id) : null,
        gruppencode: this.gruppencode,
        // voranmeldung: this.isVoranmeldung
        // termin: this.warteliste || this.isVoranmeldung ? null : this.termin.id,
        // wartelistenTermine: this.warteliste || this.isVoranmeldung ? this.selectedTermine : null,
        // voranmeldung: this.isVoranmeldung
      }
      const mutation = gql`
        mutation JfTerminAnmeldungVueCreate(
          $email: String,
          $emailConfirmation: String,
          $password: String,
          $passwordConfirmation: String,

          $gruppencode: String,
          $termin: ID,
          $wartelistenTermine: [ID],
          $voranmeldung: Boolean,

          $datenschutzAccepted: Boolean,
        ) {
          userWithTerminCreate(
            email: $email,
            emailConfirmation: $emailConfirmation,
            password: $password,
            passwordConfirmation: $passwordConfirmation,

            gruppencode: $gruppencode,
            termin: $termin,
            wartelistenTermine: $wartelistenTermine,
            voranmeldung: $voranmeldung,

            datenschutzAccepted: $datenschutzAccepted,
          ) {
            success
          }
        }`
      this.gqlAction({
        loading: 'createLoading',
        mutationName: 'userWithTerminCreate',
        // errorMsg: false,
        // successMsg: 'Account Erstellt',
        variables,
        then() {
          that.accountCreated = that.edit.email || '?'
          that.edit.password = ''
          that.edit.passwordConfirmation = ''
        },
        mutation,
      })
    },
    updateUser() {
      if (this.finished || this.userLoading > 0 || this.disableActions()) {
        return
      }
      this.plaetzeGebucht = 0
      this.gruppenlink = null
      const variables = {
        ...this.editUser,
        wartelisten: undefined,
        teilnehmer: this.editTns.map(tn => ({...tn, termin: undefined, gruppe: undefined })),
        gruppencode: this.gruppencode,
        // gruppenanmeldung: this.gruppenanmeldung == 1,
        // gruppeTerminId: this.gruppenanmeldung == 1 ? this.termin?.id : null,
        // selbeKlasse: this.warteliste == 1 && this.gruppenanmeldung == 0 ? true : this.editUser.selbeKlasse,
        // warteliste: this.warteliste == 1,
        // wartelistenTermine: this.warteliste == 1 ? this.wlTermine.map((t) => t.id) : null,
      }
      const mutation = gql`mutation ExtraMyDetailsVue(
          $email: String,
          $nachname: String,
          $vorname: String,
          $telefon: String,
          $strasse: String,
          $strasseNr: String,
          $adresszusatz: String,
          $plz: String,
          $ort: String,
          $sozialRabatt: Boolean,
          $sozialausweisFileName: String,
          $sozialausweisFile: String,
          $sozialausweisKommt: String,
          $acceptAgbs: Boolean,
          $acceptDatenschutz: Boolean,
          $acceptPhotoVideo: Boolean,
          $acceptInfoEmails: Boolean,
          $bekanntVon: [String],
          $bekanntVonSonstiges: String,
          $teilnehmer: [UserWithGruppencodeCreateInput],

          # $warteliste: Boolean,
          # $wartelistenTermine: [ID],
          # $gruppenanmeldung: Boolean,
          # $gruppeTerminId: ID,
          # $plaetze: String,
          # $selbeKlasse: Boolean,
          $gruppencode: String,
          $schuleId: ID,
          $gruppenname: String,
        ) {
          userWithTeilnehmerUpdate: userWithGruppencodeCreate(
            userExists: true
            email: $email,
            nachname: $nachname,
            vorname: $vorname,
            telefon: $telefon,
            strasse: $strasse,
            strasseNr: $strasseNr,
            adresszusatz: $adresszusatz,
            plz: $plz,
            ort: $ort,
            sozialRabatt: $sozialRabatt,
            sozialausweisFileName: $sozialausweisFileName,
            sozialausweisFile: $sozialausweisFile,
            sozialausweisKommt: $sozialausweisKommt,
            acceptAgbs: $acceptAgbs,
            acceptDatenschutz: $acceptDatenschutz,
            acceptPhotoVideo: $acceptPhotoVideo,
            acceptInfoEmails: $acceptInfoEmails,
            bekanntVon: $bekanntVon,
            bekanntVonSonstiges: $bekanntVonSonstiges,
            teilnehmer: $teilnehmer,

            # warteliste: $warteliste,
            # wartelistenTermine: $wartelistenTermine,
            # gruppeTerminId: $gruppeTerminId,
            # gruppenanmeldung: $gruppenanmeldung,
            # plaetze: $plaetze,
            # selbeKlasse: $selbeKlasse,
            gruppencode: $gruppencode,
            schuleId: $schuleId,
            gruppenname: $gruppenname,
          ) {
            success
            plaetze
          }
        }`
      this.gqlAction({
        loading: 'userLoading',
        mutationName: 'userWithTeilnehmerUpdate',
        // errorMsg: false,
        // successMsg: 'Account Erstellt',
        variables,
        then(response) {
          const update = response?.data?.userWithTeilnehmerUpdate
          if (update?.success) {
            this.finished = true
            this.gruppenlink = update.gruppenlink
            this.plaetzeGebucht = update.plaetze
            this.constants = update.constants
          } else {
            this.$store.commit("addError", { text: "Ein Fehler ist aufgetreten" })
          }
        },
        finally() {
          this.$vuetify.goTo(this.$refs.top)
        },
        mutation,
      })
      // }).then(response => {
      //   this.loading--
      //   if (response.data.userWithTeilnehmerUpdate.success) {
      //     this.$apollo.queries.user.refetch()
      //     this.editTns = []
      //     if (this.voranmeldung && this.editTns.length === 0) {
      //       this.successMsg = 'Wir bearbeiten Ihre Anmeldung schnellstmöglich, um Ihnen in Kürze einen Feiertermin mitteilen zu können. Sobald wir einen festen Termin vergeben können, erhalten Sie eine E-Mail mit allen weiteren Informationen'
      //     } else {
      //       this.successMsg = 'Speichern Erfolgreich'
      //     }
      //     this.$vuetify.goTo(this.$refs.top)
      //   }
      // }, err => {
      //   window.e = this.graphqlErrorsObject
      //   this.gqlAddErrors(err, ['userWithTeilnehmerUpdate', 'base'])
      //   this.$vuetify.goTo(this.$refs.top)
      //   this.loading--
      // })
    }
  },
}
</script>
